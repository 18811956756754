export const Connector = () => {
  return (
    <div className="connector">
      <svg
        className="connector__top"
        width="20"
        height="60"
        viewBox="0 0 20 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" stroke="white" strokeWidth="2" />
        <rect x="9" y="19" width="2" height="41" fill="white" />
      </svg>
      <svg
        className="connector__bottom"
        width="20"
        height="60"
        viewBox="0 0 20 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_220:529)">
          <circle cx="10" cy="50" r="9" stroke="#46327A" strokeWidth="2" />
          <rect x="9" y="-1" width="2" height="41" fill="#46327A" />
        </g>
        <defs>
          <clipPath id="clip0_220:529">
            <rect width="20" height="60" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
