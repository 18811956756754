import {Connector} from "../components/Connector";
import loader from "../assets/loader.svg";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import {getLandingAws, uploadImage} from "../utils/apiCalls";
// import {language} from "../language/language";


// const param = new URLSearchParams(window.location.search);
// const lang = param.get("language");
// let text = language[lang] || language.de


function ImageGenerator({text}) {

    const [selectedImage, setSelectedImage] = useState({});
    const [isImagesLoading, setIsImagesLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [fileError, setFileError] = useState("");
    const [isResultLoading, setIsResultLoading] = useState(false);



    useEffect(() => {
        setIsImagesLoading(true);
        getLandingAws().then(({ data }) => {
            const { files } = data;
            setImages(files);
            setSelectedImage(files[0]);
            setIsImagesLoading(false);
        });
    }, []);

    const handleImageLoad = async (file) => {
        if (!file) {
            return;
        }

        if ((file.size / 1024 / 1024).toFixed(4) > 5) {
            text.language === "de"
                ? setFileError("Dateigröße muss kleiner als 5 MB sein.")
                : setFileError("File size must be less than 5 MB.");
            return;
        }

        const newImageUrl = URL.createObjectURL(file);

        setSelectedImage({ path: newImageUrl });
        setIsResultLoading(true);
        uploadImage(file).then(({ data }) => {
            setSelectedImage((img) => ({ ...img, ...data }));
            setIsResultLoading(false);
        });
    };

    const handleDownloadCsvClick = () => {
        const csv = selectedImage.csv;
        const encodedUri = encodeURI(csv);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "result.csv");
        document.body.appendChild(link);
        link.click();
    };
    return (
        <>
            <section className="hero hero-three">
                <h2 className="hero__title">{text.imageGeneration}</h2>
                <p className="hero__text">{text.NlgImgIs}</p>
                <Connector />
            </section>

            <section className="image-selector">
                {isImagesLoading ? (
                    <img className="image-selector__main-loader" src={loader} alt="loader" />
                ) : (
                    <>
                        <div className="image-selector__row">
                            <div className="image-selector__previews-wrapper">
                                {images.map((file, i) => {
                                    const { path } = file;
                                    const isSelected = path === selectedImage.path;
                                    return (
                                        <Button
                                            className={`image-selector__preview-button ${
                                                isSelected ? "image-selector__preview-button--selected" : ""
                                            }`}
                                            style={{ backgroundImage: `url(${path})` }}
                                            onClick={() => setSelectedImage(file)}
                                            key={i}
                                        >

                                        </Button>
                                    );
                                })}
                            </div>
                            <Button className="file-loader">
                                <div className="file-loader__title">{text.uploadImg}</div>
                                <div className="file-loader__note">{text.format}</div>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    className="file-loader__input"
                                    onChange={(e) => handleImageLoad(e.target.files[0])}
                                />
                            </Button>
                            {fileError && <div className="image-selector__error">{fileError}</div>}
                        </div>

                        <div className="image-selector__row">
                            <div className="image-selector__image-block">
                                <div className="image-selector__selected-image-wrapper">
                                    <img
                                        className="image-selector__selected-image"
                                        src={selectedImage?.path}
                                        alt=""
                                    />
                                    {isResultLoading && (
                                        <div className="image-selector__loader-wrapper">
                                            <img className="image-selector__result-loader" src={loader} alt="loader" />
                                        </div>
                                    )}
                                </div>
                                <div className="image-selector__description">{selectedImage?.text}</div>
                            </div>
                            {!isResultLoading && selectedImage?.labels && (
                                <div className="image-specs">
                                    <div className="image-specs__row">
                                        <span className="image-specs__label">Results</span>
                                        <span className="image-specs__value">Confidence</span>
                                    </div>
                                    {selectedImage.labels.map(({ Name, Confidence }) => (
                                        <div className="image-specs__row" key={Name}>
                                            <span className="image-specs__label">{Name}</span>
                                            <span className="image-specs__value">{Confidence}%</span>
                                        </div>
                                    ))}
                                    <Button
                                        className="image-specs__download-button"
                                        onClick={handleDownloadCsvClick}
                                    >
                                        <svg
                                            width="26"
                                            height="26"
                                            viewBox="0 0 48 48"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M8 24.5H41" stroke="#C92775" strokeWidth="3" strokeLinecap="round" />
                                            <path
                                                d="M29.5 13L41 24.5L29.5 36"
                                                stroke="#C92775"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                        Download List
                                    </Button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </section>
        </>
    )
}

export default ImageGenerator;
