import React from "react";
import {Button, MenuItem, OutlinedInput, Select} from "@mui/material";
import {Connector} from "../components/Connector";
import {CopyButton} from "../components/CopyButton";
import {ResultPreview} from "../components/ResultPreview";
import {useEffect, useRef, useState} from "react";
import {generateText, getLandingData} from "../utils/apiCalls";

const randomArrayItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

const LangGenerator = React.forwardRef(({text},ref)=>{
    const result = useRef();

    const defaultAbout = text.language === "de" ?
        [
            "VW Golf",
            "Tesla Model 3",
            "Bosch Akkuschrauber",
            "Miele Geschirrspüler",
            "Samsung Galaxy S21",
        ]:
        [
            "VW Golf",
            "Tesla Model 3",
            "Bosch cordless screwdriver",
            "Miele dishwasher",
            "Samsung Galaxy S21",
        ];

    const [presets, setPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState("");
    const [aboutInput, setAboutInput] = useState(randomArrayItem(defaultAbout));
    const [generatedResult, setGeneratedResult] = useState(null);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isEn = text.language === 'en';
        getLandingData(isEn).then(({ data }) => {
            const { presets } = data;
            setSelectedPreset(presets[0].id || "");
            setPresets(presets);
        });
    }, []);

    const onCopyClick = () => {
        navigator.clipboard.writeText(generatedResult.text).then(() => {});
    };

    const handleGenerateClick = async () => {
        result.current.scrollIntoView({ behavior: "smooth", block: "center" });
        setError("");
        setIsLoading(true);
        const reqData = {
            preset_id: selectedPreset,
            about: aboutInput,
        };
        await generateText(reqData)
            .then(({ data }) => {
                setGeneratedResult(data);
            })
            .catch(({ response }) => {
                if (response?.status === 429) {
                    text.language === "de" ?
                        setError(
                            "Das Anfragelimit wurde erreicht. Bitte versuchen Sie es in 3 Minuten erneut.",
                        ) :
                        setError(
                            "The request limit has been reached. Please try again in 3 minutes.",
                        );
                } else {
                    text.language === "de" ?
                        setError(
                            "Leider ist etwas schief gelaufen. Bitte probieren Sie es erneut.",
                        ) :
                        setError(
                            "Unfortunately, something went wrong. Please try it again.",
                        );
                }
            });
        setIsLoading(false);
    };

    const formatResultDate = (date) => {
        const newDate = new Date(date);
        return text.language === "de" ?
            `${newDate.toLocaleString("de-DE", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            })} Uhr` :
            `${newDate.toLocaleString("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            })}`
    };

    return (
        <>
            <section className="hero hero-two" ref={ref}>
                <h2 className="hero__title">{text.langGeneration}</h2>
                <p className="hero__text">
                    {text.NlgLangIs}
                </p>
                <div className="actions">
                    <label className="actions__select-label">{text.need}</label>
                    <div className="actions__select-wrapper">
                        <Select
                            displayEmpty
                            defaultValue={presets[1]?.id}
                            className="actions__select"
                            value={selectedPreset}
                            onChange={(e) => setSelectedPreset(e.target.value)}
                        >
                            {presets.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <label className="actions__input-label">{text.about}</label>
                    <div className="actions__input-wrapper">
                        <OutlinedInput
                            className="actions__input"
                            value={aboutInput}
                            onChange={(e) => setAboutInput(e.target.value)}
                        />
                    </div>
                    <div className="actions__button-wrapper">
                        <Button
                            className="actions__generate-button"
                            variant="contained"
                            onClick={handleGenerateClick}
                            // disabled={!aboutInput || !selectedPreset}
                        >
                            {text.generateText}
                        </Button>
                    </div>
                </div>
                <Connector />
            </section>

            <section className="result-section" ref={result}>
                {generatedResult && !isLoading && !error ? (
                    <div className="result-generated">
                        <div className="result-generated__heading">
                            <span>{`${text.your} "${generatedResult.presetName}"`}</span>
                            <span>{`${text.onTheSubject} "${generatedResult.about}"`}</span>
                        </div>
                        <div className="result-generated__card">
                            <div className="result-generated__text">{generatedResult.text}</div>
                            <div className="result-generated__footer">
											<span className="result-generated__date">
												{formatResultDate(generatedResult.date)}
											</span>
                                <CopyButton onClick={onCopyClick} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <ResultPreview isLoading={isLoading} error={error} text={text}/>
                )}
            </section>
        </>
    )


})
export default LangGenerator;