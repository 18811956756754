import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  ThemeProvider
} from "@mui/material";


import { createTheme } from '@mui/material/styles';
import {useEffect, useState} from "react";
import {createWebinarUser} from "../utils/apiCalls";

const theme = createTheme({
  palette: {
    secondary: {
      main: '#000154',
    },
  },
});

export const EventModal = ({ open, onClose}) => {


  const [userObj, setUserObj] = useState({
    name: "",
    email: ""
  });

  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [isError, setIsError] = useState(false)


  useEffect(()=>{
    setTimeout(()=>{
      setIsConfirmModal(false);
    },500)
    setIsError(false);
    resetMailObj();
  },[open])


  const  handleChangeName = (e) =>{
    setUserObj( prev => ({
      ...prev,
      name: e.target.value
    }))
  }

  const  handleChangeEmail = (e) =>{
    setIsError(false);
    setUserObj( prev => ({
      ...prev,
      email: e.target.value
    }))
  }

  const createRequest = async () => {
    await createWebinarUser(userObj)
        .then((data ) => {
          setIsConfirmModal(true)
        })
        .catch(({ response }) => {
          setIsError(true)
        })

  }

  const resetMailObj = () =>{
    setUserObj( prev => ({
      ...prev,
      name: "",
      email: "",
    }));
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth={"sm"} style={{minWidth: '374px'}} fullWidth={true}>
      {!isConfirmModal ? (
      <div className="webinar-modal">
        <div className="webinar-modal__header">
          <h3 className="webinar-modal__title">Anmeldung zum Live-Event</h3>
          <IconButton className="webinar-modal__close-button" onClick={()=>{onClose();}}>
            <CloseRoundedIcon fontSize={'large'} />
          </IconButton>
        </div>
        <div className="webinar-modal__body">
          <p className="webinar-modal__description">
            Hier können Sie sich zum Live-Event mit Ihrer E-Mail-Adresse anmelden. Im Anschluss werden wir uns noch einmal persönlich bei Ihnen melden.
          </p>
          <p className="webinar-modal__description" style={{fontWeight:"700"}}>
            Impact Hub Stuttgart, Quellenstraße 7a, 70376 Stuttgart Am Freitag, 3. Juni 2022 um 15:00 - 17:00 Uhr
          </p>
          <ThemeProvider theme={theme}>
            <FormControl className="email-form">
              <InputLabel className="email-label" htmlFor="component-name" color="secondary">Name</InputLabel>
              <OutlinedInput
                  id="component-name"
                  className="email-input"
                  color="secondary"
                  label="Name"
                  value={userObj.name}
                  onChange={(e)=>{handleChangeName(e)}}
              />
            </FormControl>
            <FormControl error={isError} className="email-form">
              <InputLabel className="email-label" htmlFor="component-mail" color="secondary">E-Mail-Adresse</InputLabel>
              <OutlinedInput
                  id="component-mail"
                  className="email-input"
                  color="secondary"
                  label="E-Mail-Adresse"
                  value={userObj.mail}
                  onChange={(e)=>{handleChangeEmail(e)}}
              />
              {isError && <FormHelperText id="component-mail">The given data was invalid</FormHelperText>}
            </FormControl>
          </ThemeProvider>
          <Button className="webinar-modal__button" onClick={createRequest}>
            Jetzt unverbindlich anmelden
          </Button>
        </div>
      </div>
          ):(
          <div className="webinar-modal">
            <div className="webinar-modal__header">
              <h3 className="webinar-modal__title">Anmeldung zum Live-Event</h3>
              <IconButton className="webinar-modal__close-button" onClick={()=>{onClose();}}>
                <CloseRoundedIcon fontSize={'large'} />
              </IconButton>
            </div>
            <div className="webinar-modal__body">
              <p className="webinar-modal__description">
                Vielen Dank für Ihre Anmeldung. Wir werden uns in den nächsten Tagen noch einmal persönlich bei Ihnen melden.
              </p>
            </div>
          </div>
          )}
    </Dialog>
  );
};
