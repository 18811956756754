import { Button } from "@mui/material";

export const CopyButton = ({ onClick }) => {
  return (
    <Button className="copy-button" onClick={onClick}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 17H14C11.7909 17 10 18.7909 10 21V36C10 38.2091 11.7909 40 14 40H24C26.2091 40 28 38.2091 28 36V30"
          stroke="#27154F"
          strokeWidth="3"
        />
        <rect
          x="20"
          y="7"
          width="18"
          height="23"
          rx="4"
          stroke="#27154F"
          strokeWidth="3"
        />
      </svg>
    </Button>
  );
};
