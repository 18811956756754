export const language = {
    de: {
        uploadImg: "Eigenes Bild hochladen",
        format: "jpeg. oder .png Format // max. 5 MB",
        language: "de",
        webinarTerm:"Webinar-Termine",
        langGeneration: "next Natural Language Generation",
        imageGeneration: "next Image Tag Generator",
        NlgLangIs: "Next NLG ist ein Sprachmodell, das Deep Learning verwendet, um menschenähnlichen Text zu erzeugen. Probieren Sie es jetzt aus!",
        NlgImgIs: "next ITG ist ein Machine Learning Service, welcher es Ihnen ermöglicht in Echtzeit alle Ihre Bilder mithilfe von Tags zu strukturieren.",
        need: "Ich benötige eine",
        about: "zum Thema",
        generateText: "Text generieren",
        enterKeyword: "Trage ein Keyword deiner Wahl in das obere Feld ein und lasse dir einen individuellen Beschreibungstext generieren",
        readyTakeoff: "Ready for Takeoff",
        joinFree: "Sichern Sie sich jetzt einen unverbindlichen Webinar-Termin, bei dem wir Ihnen die next NLG-Funktionen anschaulich erklären.",
        completed: "Ausgebucht",
        join: "Teilnahme sichern",
        webinar: "Webinaranmeldung",
        signUp: "Unverbindlich anmelden",
        questions: "Fragen?",
        contact: "Schreibe uns einfach an",
        underContact: "Wir melden uns zeitnah bei Ihnen!",
        imprint: "Impressum",
        legal: "Rechtliche Hinweise",
        allRights: "alle Rechte vorbehalten",
        time: "Uhrzeit",
        waiting: "Bitte warte einen Moment...",
        your: "Deine",
        onTheSubject: "zum Thema"
    },
    en: {
        language: "en",
        webinarTerm:"Webinar",
        langGeneration: "next Natural Language Generation",
        imageGeneration: "next Image Tag Generator",
        NlgLangIs: "Next a language model that uses deep learning to generate human-like text. Try it now!",
        NlgImgIs: "next ITG is a machine learning service that allows you to structure all your images using tags in real time.",
        uploadImg: "Upload your image",
        format: ".jpeg or .png format // max. 5 MB",
        readyTakeoff: "Ready for Takeoff",
        joinFree: "Join our free webinar to find out in detail how our technology works and how you could use it.",
        completed: "Completed",
        join: "Join",
        webinar: "Webinar",
        signUp: "Sign up for free",
        questions: "Questions?",
        contact: "Contact us via",
        underContact: " ",
        imprint: "Imprint",
        legal: "Legal notice",
        allRights: "All rights reserved",
        time: "Time",
        need: "I need a",
        about: "about the topic",
        generateText: "Generate text",
        enterKeyword: "Enter a keyword of your choice in the field above and have an individual text generated for you",
        waiting: "Please wait a moment...",
        your: "Your",
        onTheSubject: "about"
    }

}