import axios from "axios";

const request = axios.create({
  baseURL: `https://demo.next-nlg.com/api`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getLandingData = (isEn) => {
  return request({
    method: "GET",
    url: isEn ? `/landing?language=en` : `/landing?language=de`,
  });
};

export const getLandingAws = () => {
  return request({
    method: "GET",
    url: `/landing/aws`,
  });
};

export const uploadImage = (file) => {
  const data = new FormData();
  data.append('image', file);

  return request({
    method: "POST",
    url: `/landing/aws/get-labels`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};


export const generateText = (data) => {
  return request({
    method: "POST",
    url: `/landing/generate`,
    data: JSON.stringify(data),
  });
};

export const createWebinarUser= (data) => {
  return request({
    method: "POST",
    url: `/landing/create-webinar-user`,
    data: JSON.stringify(data),
  });
};
