import {useEffect, useRef, useState} from "react";
import './App.css';

import {Logo} from "./components/Logo";
import pic_1 from "./assets/180911-wizemann-SPACE-18.jpg";
import pic_2 from "./assets/180911-wizemann-SPACE-5 1.jpg";
import pic_3 from "./assets/180911-wizemann-SPACE-9.jpg";
import pic_4 from "./assets/180911-wizemann-SPACE-13.jpg";
import pic_5 from "./assets/180911-wizemann-SPACE-3 1.jpg";
import pic_6 from "./assets/180911-wizemann-SPACE-15.jpg";
import pic_7 from "./assets/180911-wizemann-SPACE-4 1.jpg";
import pic_8 from "./assets/180911-wizemann-SPACE-14 1.jpg";

import juergenFruechtImg from "./assets/juergen-fruecht.jpg";
import raifKablaouiImg from "./assets/raif-kablaoui.jpg";

import { StyledEngineProvider } from "@mui/material/styles";
import {AppBar, Box, Button, Container, MenuItem, OutlinedInput, Paper, Select,} from "@mui/material";
import {Connector} from "./components/Connector";

import {language} from "./language/language"
import {ArrowLink} from "./components/ArrowLink";
import LangGenerator from "./modules/LangGenerator";
import ImageGenerator from "./modules/ImageGenerator";
import {EventModal} from "./components/EventModal";


const param = new URLSearchParams(window.location.search);
const lang = param.get("language");
let text = language[lang] || language.de



function App() {
    const currentYear = new Date().getFullYear();

    const top = useRef();
    const navBar = useRef();
    const generatorSection = useRef();
    const informationSection = useRef();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const openWebinarLink = () => {
        window.open("https://register.gotowebinar.com/register/6578033245605857549");
    };


    const handleLogoClick = () => {
        top.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleGeneratorNavClick = () => {

        window.scrollTo({
            top: generatorSection.current.offsetTop - navBar.current.clientHeight,
            behavior: 'smooth'
        });

    };

    const handleInformationNavClick = () => {
        window.scrollTo({
            top: informationSection.current.offsetTop - navBar.current.clientHeight,
            behavior: 'smooth'
        });
    };

    return (
      <StyledEngineProvider injectFirst>
        <div className="App" ref={top}>
            <AppBar className="header" ref={navBar}>
                <Container className="container header__container">
                    <Button onClick={handleLogoClick}>
                        <Logo />
                    </Button>
                    <div className="header__buttons">
                        <Button className="header__button" onClick={handleGeneratorNavClick}>
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M8 24.5H41" stroke="#27154F" strokeWidth="3" strokeLinecap="round" />
                                <path
                                    d="M29.5 13L41 24.5L29.5 36"
                                    stroke="#27154F"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                />
                            </svg>
                            <p>Tools ausprobieren</p>
                        </Button>
                        <Button className="header__button" onClick={handleInformationNavClick}>
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M8 24.5H41" stroke="#27154F" strokeWidth="3" strokeLinecap="round" />
                                <path
                                    d="M29.5 13L41 24.5L29.5 36"
                                    stroke="#27154F"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                />
                            </svg>
                            <p>Jetzt zum Live-Event anmelden</p>
                        </Button>
                    </div>
                </Container>
            </AppBar>
            <main>
                <Container className="container">
                    <section className="hero hero-one">
                        <p className="hero__text">Automatische Textgenerierung und Bildanalyse</p>
                        <h1 className="hero__title">Einladung zu unserem Event über zukunftsweisende KI-Technologien</h1>
                        <div className="hero__event">
                            <Box className="hero__date date">
                                <Paper className="date__day date__block">
                                    <h3>Datum</h3>
                                    <p>Freitag, 3. Juni 2022</p>
                                </Paper>
                                <Paper className="date__time date__block">
                                    <h3>Uhrzeit</h3>
                                    <p>15:00 - 17:00 Uhr</p>
                                </Paper>
                            </Box>
                            {/*}
                            <Box className="hero__location location">
                                <Paper className="location__block">
                                    <h3>Location</h3>
                                    <p>Impact Hub Stuttgart, Quellenstraße 7a, 70376 Stuttgart</p>
                                    <a href='https://stuttgart.impacthub.net/' target="_blank">https://stuttgart.impacthub.net/</a>
                                </Paper>
                            </Box>
                            */}

                            <Button className="hero__event-button" onClick={()=>{openWebinarLink()}}>
                                Jetzt unverbindlich anmelden
                            </Button>
                            {/*
                            <a className="hero__event-button" href="https://register.gotowebinar.com/register/6578033245605857549" target="_blank">
                            Jetzt anmelden
                            </a>
                            */}
                        </div>

                        <Connector />
                    </section>

                    <section className="information" ref={informationSection}>
                        <h2 className="information__title">next NLG - Natural Language Generation</h2>
                        <h2 className="information__title">next ITG - Image Tag Generation </h2>
                        <p className="information__description">Automatische Generierung von Produkt- oder SEO-Texten? Automatisierte Beschreibung von Bildinhalten auf Basis von Stichworten oder Beschreibungstexten? Automatische Übersetzung der erzeugten Inhalte in mehr als 30 Sprachen? Direkte Speicherung der Inhalte in Datenbanken oder einer Shop-Anwendung?</p>
                        <div className="information__block">
                            <p className="information__text">
                                Wenn Sie die Umsetzung dieser Anforderungen live erleben möchten, freuen wir uns auf Ihre Teilnahme an unserem KI-Event im Impact Hub Stuttgart! Jürgen Frücht und Raif Kablaoui werden neben der allgemeinen Einführung in die KI-basierte automatisierte Text- und Bildtagging-Generierung verschiedene Anwendungsfälle live im Rahmen des Events präsentieren.
                            </p>
                            <div className="information__aside">
                                <p>Melden Sie sich schon jetzt zu unserem Event an. Wir freuen uns auf Ihre Teilnahme!</p>
                                <Button className="information__button" onClick={()=>{openWebinarLink()}}>
                                    Jetzt unverbindlich anmelden
                                </Button>
                            </div>
                        </div>
                    </section>

                    <section className="showcase">
                        <div className="showcase__aside showcase__aside-left">
                            <div className="showcase__img showcase__left"><img src={pic_1} alt="pic_1"/></div>
                        </div>
                        <div className="showcase__center">
                            <div className="showcase__row-1"><div className="showcase__img"><img src={pic_3} alt="pic_3"/></div><div className="showcase__img"><img src={pic_4} alt="pic_4"/></div><div className="showcase__img"><img src={pic_5} alt="pic_5"/></div></div>
                            <div className="showcase__row-2"><div className="showcase__img"><img src={pic_6} alt="pic_6"/></div><div className="showcase__img"><img src={pic_7} alt="pic_7"/></div><div className="showcase__img"><img src={pic_8} alt="pic_8"/></div></div>
                        </div>
                        <div className="showcase__aside  showcase__aside-right">
                            <div className="showcase__img showcase__right"><img src={pic_1} alt="pic_1"/></div>
                            <div className="showcase__img"><img src={pic_2} alt="pic_2"/></div>
                        </div>
                    </section>

                    <section className="contact">
                        <h2 className="contact-title">Speaker & Kontakt</h2>
                        <p className="contact-subtitle">Wir freuen uns auf das gemeinsame Event mit Ihnen!</p>

                        <div className="contact-block">
                            <div className="contact-items">
                                <div className="contact-item">
                                    <div className="contact-image">
                                        <img src={juergenFruechtImg}/>
                                    </div>
                                    <p className="contact-name">Jürgen Frücht</p>
                                </div>
                                <div className="contact-item">
                                    <div className="contact-image">
                                        <img src={raifKablaouiImg}/>
                                    </div>
                                    <p className="contact-name">Raif Kablaoui</p>
                                </div>
                            </div>

                            <div className="contact-info">
                                <p className="contact-address">Kittelberger media solutions GmbH</p>
                                <p className="contact-address">Bayernstraße 8, 72768 Reutlingen</p>
                                <a className="contact-mail" href="mailto:juergen.fruecht@kittelberger.de">juergen.fruecht@kittelberger.de</a>
                                <a className="contact-webSite" href="https://www.kittelberger.de" target="_blank">www.kittelberger.de</a>
                                <a className="contact-newsletterBtn" href="https://www.kittelberger.de/newsletter-anmeldung" target="_blank">Jetzt Newsletter abonnieren</a>
                            </div>
                        </div>
                    </section>

                    <LangGenerator text={text} ref={generatorSection} />
                    <ImageGenerator text={text} />

                </Container>

            </main>
            <footer className="footer">
                <Container className="container">
                    <div className="footer__inner">
                        <Logo isWhite />
                        <div className="footer__info">
                            <p className="footer__copyrights">
                                © next-nlg.com {currentYear}, {text.allRights}
                            </p>
                            <ArrowLink href={"https://www.kittelberger.de/impressum"}>
                                {text.imprint}
                            </ArrowLink>
                            <ArrowLink href={"https://www.kittelberger.de/datenschutz"}>
                                {text.legal}
                            </ArrowLink>
                        </div>
                    </div>
                </Container>
            </footer>

            <EventModal
                open={isModalOpen}
                onClose={handleCloseModal}
            />
        </div>
      </StyledEngineProvider>
  );
}

export default App;
